import DataError from '@/components/Error.vue';
import Loading from '@/components/Loading.vue';
import NotAsked from '@/components/NotAsked.vue';

export const wrap = component => ({
  name: 'RemoteData',
  render: function(createElement) {
    return this.$store.state.daily_data.cata({
      'NotAsked': () => createElement(NotAsked, [],),
      'Loading': () => createElement(Loading, []),
      'Error': e => createElement(DataError, {props: {error: e}}),
      'Success': d => createElement(
        component,
        {
          props: {
            name: this.$store.state.route.params.name,
            slug: this.$store.state.route.params.slug,
            data: d,
            commodities: this.$store.state.commodities
          }
        }
      )
    })
  }
})

export const wrapPositions = component => ({
  name: 'RemoteData',
  render: function(createElement) {
    return this.$store.state.positions_data.cata({
      'NotAsked': () => createElement(NotAsked, [],),
      'Loading': () => createElement(Loading, []),
      'Error': e => createElement(DataError, {props: {error: e}}),
      'Success': d => createElement(
        component,
        {
          props: {
            data: d
          }
        }
      )
    })
  }
})

export const wrapOceanBoltAIS = component => ({
  name: 'RemoteData',
  render: function(createElement) {
    return this.$store.state.oceanbolt_ais_data.cata({
      'NotAsked': () => createElement(NotAsked, [],),
      'Loading': () => createElement(Loading, []),
      'Error': e => createElement(DataError, {props: {error: e}}),
      'Success': d => createElement(
        component,
        {
          props: {
            data: d
          }
        }
      )
    })
  }
})
