import DataFrame from 'dataframe-js'
import * as Moment from 'moment'
import { extendMoment } from 'moment-range'
import { buildTenorsData, getTenorForDateAndMonth } from './tenors'
import {
  buildRecommendationsTableData,
  buildExitIndicationsTableData,
  buildM2MTableData,
  buildPortfolioTableData,
  buildAccountValuePlotData,
  buildClosePricesPlotData
} from './equities'
import {
  buildRouteD3ForecastData,
  buildRouteD3FeaturesData,
  buildRouteD3PerformanceData,
} from './routes'
import {
  viterraLabels,
  buildViterraD3ForecastData,
  buildViterraD3FeaturesData,
  buildViterraD3PerformanceData
} from './viterra'
export * from './ais'
export * from './commodities'
export * from './equities'
export * from './positions'
export * from './tenors'

const moment = extendMoment(Moment)

const buildOpenTradesCardsData = function(dayData, commodities) {
  return commodities.reduce(
    (d, commodity) => ({
      ...d,
      [commodity]: {
        markToMarket: dayData[commodity + '_m2m'],
        partiallyRealized: dayData[commodity + '_partially_realized'],
        marginExposure: dayData[commodity + '_margin_exposure'],
        premiumExposure: dayData[commodity + '_premium_exposure'],
        delta: dayData[commodity + '_delta'],
        count: dayData[commodity + '_open_trades']
      }
    }),
    {
      portfolio: {
        valueAtRisk: dayData.value_at_risk,
        expectedShortfall: dayData.expected_shortfall,
        markToMarket: dayData.mark_to_market,
        partiallyRealized: dayData.partially_realized,
        marginExposure: dayData.margin_exposure,
        premiumExposure: dayData.premium_exposure,
        delta: dayData.delta,
        count: dayData.open_trades
      }
    }
  )
}

const buildRealizedCardData = function(dayData, firstDate) {
  return ['ytd', 'tmr'].reduce(
    (d, key) => ({
      ...d,
      [key]: {
        cumulativeNetProfit: dayData[key + '_cum_net_profit'],
        profitLossRatio: dayData[key + '_profit_loss_ratio'],
        averageNetProfitPerTrade: dayData[key + '_avg_net_profit_per_trade'],
        averageTradeDuration: dayData[key +  '_avg_trade_duration'],
        count: dayData[key + '_realized_trades'],
        averageVaR: dayData[key + '_avg_var'],
        maxVaR: dayData[key + '_max_var'],
        sharpeRatio: null,
        sortinoRatio: null
      }
    }),
    {
      overall: {
        title: moment(firstDate).format('MMM YY') + ' - Present',
        cumulativeNetProfit: dayData.cum_net_profit,
        profitLossRatio: dayData.profit_loss_ratio,
        averageNetProfitPerTrade: dayData.avg_net_profit_per_trade,
        averageTradeDuration: dayData.avg_trade_duration,
        count: dayData.realized_trades,
        averageVaR: dayData.avg_var,
        maxVaR: dayData.max_var,
        sharpeRatio: dayData.sharpe_ratio,
        sortinoRatio: dayData.sortino_ratio
      }
    }
  )
}

const diffToClass = x => ({[(x[0] ==  '+' ? 'text-success' : 'text-danger')]: true})

const diff = function(key, day, previousDay) {
  const value = Number(day[key]) - Number(previousDay[key])
  return (value < 0 ? '' : '+') + String(value)
}

const recommendationToClass = function(recommendation) {
  const cls = {'float-right': true}
  if (recommendation == 'B') {
    cls['text-success'] = true
  } else if (recommendation == 'S') {
    cls['text-danger'] = true
  }
  return cls
}

const closestDate = (df, date) => {
  const allDates = df.toArray('date')
  if (!date) return allDates[allDates.length-1]
  return allDates.filter(d => d <= date).slice(-1)[0]
}

const buildForecastGraphs = function(date, df, dayData, previousDayData, tenors, commodities) {
  const [mm3, mm2, mm1] = tenors.filter(row => row.get('end') < date).tail(3).toCollection()
  const [m0, m1, m2, m3] = tenors.filter(row => row.get('end') >= date).head(4).toCollection()

  return commodities.reduce((acc, commodity) => {
    const pastData = [mm3, mm2, mm1, m0].map(t => {
      const startDate = t.settlementStart
      const endDate = t.settlementDate > date ? date : t.settlementDate

      const slice = df.filter(row => row.get('date') >= startDate && row.get('date') <= endDate)
      const spotDates = slice.toArray('date')
      const spotKey = commodity + '_Spot_price'
      const spotPrices = slice.toArray(spotKey)
      const settlement = slice.stat.mean(spotKey)
      return {
        spotDates,
        spotPrices,
        settlements: Array(spotPrices.length).fill(settlement)
      }
    })

    const futureData = [m1, m2, m3].map((t, ix) => {
      const forecastKey = (contract, engine=1) => (
        commodity + '_forecast_engine_' + engine + '_M' + contract
      )
      const FFAPriceKey = contract => (
        commodity + '_FFA_price_M'+ contract
      )

      const dates = Array.from(
        moment.range(
          t.settlementStart,
          t.settlementDate
        ).by('day')
      ).map(x => x.format('YYYY-MM-DD'))

      const forecasts = Array(dates.length).fill(
        Math.round(dayData[forecastKey(ix+1)])
      )
      const previousForecasts = Array(dates.length).fill(
        Math.round(previousDayData[forecastKey(ix+1)])
      )
      const FFAPrices = Array(dates.length).fill(
        Math.round(dayData[FFAPriceKey(ix+1)])
      )
      const previousFFAPrices = Array(dates.length).fill(
        Math.round(previousDayData[FFAPriceKey(ix+1)])
      )
      const forecasts2E = Array(dates.length).fill(null)
      const previousForecasts2E = Array(dates.length).fill(null)
      const has2E = !!dayData[forecastKey(ix+1, 2)]
      if (has2E) {
        forecasts2E.fill(
          Math.round(dayData[forecastKey(ix+1, 2)])
        )
        previousForecasts2E.fill(
          Math.round(previousDayData[forecastKey(ix+1, 2)])
        )
      }
      return {
        dates,
        forecasts,
        previousForecasts,
        forecasts2E,
        previousForecasts2E,
        FFAPrices,
        previousFFAPrices
      }
    })
    const spotDates = [].concat(...pastData.map(x => x.spotDates))
    const forecastDates = [].concat(...futureData.map(x => x.dates))

    acc[commodity] = [
      {
        type: 'scatter',
        x: spotDates,
        y: [].concat(...pastData.map(x => x.spotPrices)),
        mode: 'lines',
        line: {color: 'rgba(243, 92, 16, 0.75)'},
        name: 'Spot Price',
      },
      {
        type: 'scatter',
        x: spotDates,
        y: [].concat(...pastData.map(x => x.settlements)),
        mode: 'lines',
        line: {color: 'rgba(243, 92, 16, 0.75)', dash: 'dot'},
        name: 'Settlement',
      },
      {
        type: 'scatter',
        x: forecastDates,
        y: [].concat(...futureData.map(x => x.forecasts)),
        mode: 'lines',
        line: {color: 'rgba(16, 54, 243, 0.5)'},
        name: "Forecast",
      },
      {
        type: 'scatter',
        x: forecastDates,
        y: [].concat(...futureData.map(x => x.previousForecasts)),
        mode: 'lines',
        line: {color: 'rgba(16, 54, 243, 0.5)', dash: 'dot'},
        name: "Forecast (Previous)",
      },
      {
        type: 'scatter',
        x: forecastDates,
        y: [].concat(...futureData.map(x => x.forecasts2E)),
        mode: 'lines',
        line: {color: 'rgba(16, 54, 243, 0.25)'},
        name: "2nd Engine Forecast",
      },
      {
        type: 'scatter',
        x: forecastDates,
        y: [].concat(...futureData.map(x => x.previousForecasts2E)),
        mode: 'lines',
        line: {color: 'rgba(16, 54, 243, 0.25)', dash: 'dot'},
        name: "2nd Engine Forecast (Previous)",
      },
      {
        type: 'scatter',
        x: forecastDates,
        y: [].concat(...futureData.map(x => x.FFAPrices)),
        mode: 'lines',
        line: {color: 'rgba(66, 248, 6, 0.8)'},
        name: "FFA Price",
      },
      {
        type: 'scatter',
        x: forecastDates,
        y: [].concat(...futureData.map(x => x.previousFFAPrices)),
        mode: 'lines',
        line: {color: 'rgba(66, 248, 6, 0.8)', dash: 'dot'},
        name: "FFA Price (Previous)",
      }
    ]
    return acc
  }, {})
}

const buildContractsData = function(date, tenors, dayData, previousDayData, graphData, commodities) {
  const data = {}
  for (let i = 0; i < commodities.length; i++) {
    for (let contractMonth = 1; contractMonth <= 3; contractMonth++) {
      const commodity = commodities[i]
      const marketPriceKey = commodity + '_FFA_price_M' + contractMonth
      const marketPrice = dayData[marketPriceKey]
      const marketPriceDiff = diff(marketPriceKey, dayData, previousDayData)
      const marketPriceDiffClass = diffToClass(marketPriceDiff)
      const spotPriceKey = commodity + '_Spot_price'
      const spotPrice = dayData[spotPriceKey]
      const spotPriceDiff = diff(spotPriceKey, dayData, previousDayData)
      const spotPriceDiffClass = diffToClass(spotPriceDiff)
      const recommendation = dayData[commodity + '_Recommendation_M' + contractMonth]
      const blocked = dayData[commodity + '_Blocked_M' + contractMonth]
      const initialMargin = dayData[commodity + '_initial_margin_M' + contractMonth]
      const maintenanceMargin = dayData[commodity + '_maint_margin_M' + contractMonth]
      const gap = dayData[commodity + '_Gap_M' + contractMonth]
      const forecast = dayData[commodity + '_forecast_engine_1_M' + contractMonth]
      const callPut = recommendation == 'B' ? 'call' : 'put'
      const U1StrikePrice = dayData[commodity + '_U1_Strike_price_' + callPut + '_M' + contractMonth]
      const U2StrikePrice = dayData[commodity + '_U2_Strike_price_' + callPut + '_M' + contractMonth]
      const U3StrikePrice = dayData[commodity + '_U3_Strike_price_' + callPut + '_M' + contractMonth]
      const U1RawPremium = dayData[commodity + '_U1_Premium_M' + contractMonth]
      const U2RawPremium = dayData[commodity + '_U2_Premium_M' + contractMonth]
      const U3RawPremium = dayData[commodity + '_U3_Premium_M' + contractMonth]
      const U1Premium = Math.round(U1RawPremium / 30.0)
      const U2Premium = Math.round(U2RawPremium / 30.0)
      const U3Premium = Math.round(U3RawPremium / 30.0)
      const CV = dayData[commodity + '_CV_filter_M' + contractMonth]
      const impliedVolatility = dayData[commodity + '_Imp_Vol_M' + contractMonth]
      const makeGraph = (col, name) => ({
          type: 'scatter',
          x: graphData.toArray('date'),
          y: graphData.toArray(col),
          name
      })
      const marketPricesGraph = [
        makeGraph(commodity + '_FFA_price_M' + contractMonth, 'Market Price'),
        makeGraph(commodity + '_Spot_price', 'Spot Price')
      ]
      const CVGraph = [
        makeGraph(commodity + '_CV_filter_M' + contractMonth, 'CV Value')
      ]
      const makePayoffGraph = function(strikeLevel, premium, strikePrice, width=4000) {
        const x = []
        for (let i = marketPrice - width; i < marketPrice + width; i += 250) {
          x.push(i)
        }
        const compute = function(x) {
          let value = premium
          if ((x > strikePrice && recommendation == "B") ||
              (x < strikePrice && recommendation == "S")) {
            value = Math.abs(x - strikePrice) + premium
          }
          return value
        }
        const y = x.map(compute)
        const name = strikeLevel + ' Strike'
        return {type: 'scatter', x, y, name}
      }
      const payoffGraph = [
        makePayoffGraph("U1", U1Premium, U1StrikePrice),
        makePayoffGraph("U2", U2Premium, U2StrikePrice),
        makePayoffGraph("U3", U3Premium, U3StrikePrice)
      ]
      let enginesData = []
      const engineNames = ['Main Engine', '2nd Engine', '3rd Engine']
      for (let i = 1; i <= 3; i++) {
        const rec = dayData[commodity + '_recommendation_engine_' + i + '_M' + contractMonth]
        const fcast = dayData[commodity + '_forecast_engine_' + i + '_M' + contractMonth]
        const ng_gap = dayData[commodity + '_gap_engine_' + i + '_M' + contractMonth]
        const recs = {
          B: 'Buy',
          S: 'Sell',
          N: 'Do Nothing'
        }
        if (rec) {
          enginesData.push({
            name: engineNames[i-1],
            recommendation: recs[rec],
            forecast: Math.round(fcast),
            gap: ng_gap && ng_gap.toFixed(2)
          })
        }
      }
      data[commodities[i] + '_M' + contractMonth] = {
        contract: 'M' + contractMonth,
        commodity: commodities[i],
        month: getTenorForDateAndMonth(tenors, date, contractMonth).month,
        date,
        recommendation,
        blocked,
        initialMargin,
        maintenanceMargin,
        gap,
        forecast,
        rawRecommendation: recommendation,
        recommendationClass: recommendationToClass(recommendation),
        marketPrice,
        marketPriceDiff,
        marketPriceDiffClass,
        spotPrice,
        spotPriceDiff,
        spotPriceDiffClass,
        U1StrikePrice,
        U2StrikePrice,
        U3StrikePrice,
        U1Premium,
        U2Premium,
        U3Premium,
        CV,
        impliedVolatility,
        payoffGraph,
        marketPricesGraph,
        CVGraph,
        enginesData
      }
    }
  }
  return data
}

export const buildDailyData = function(date, data) {
  const columns = [
    'index', 'date', ...data.dashboard.columns
  ]
  let dates = Object.keys(data.dashboard.dates).sort()
  const rows = data.dashboard.data.map((row, ix) => {
    return [ix, dates[ix], ...row]
  })
  let df = new DataFrame(rows, columns)
  if (data.type == 'routes') {
    // Work around broken data on baltic holidays
    const key = `${data.dashboard.config.routes[0].name}_W1_spot_price`
    df = df.filter(
      row => row.get(key)
    )
    dates = df.toArray('date')
  }
  if (data.type == 'equities') {
    // expand the columns
    df = df.withColumn(
      'account_value',
      row => (
        data.configuration.parameters.start_capital +
        row.get('net_outcome') +
        row.get('m2m')
      )
    ).withColumn(
      'available_capital',
      row => (
        data.configuration.parameters.start_capital +
        row.get('m2m')
      )
    ).withColumn(
      'pct_avail_cap_used',
      row => (
        (row.get('available_capital') - row.get('balance')) /
        row.get('available_capital')
      )
    )
  }
  const effectiveDate = closestDate(df, date)
  const dayData = df.filter(row => row.get('date') == effectiveDate).toCollection()[0]
  const previousDayData = df.filter(row => row.get('index') == dayData.index-1).toCollection()[0]
  const firstDate = dates[0]

  const record = {
    date: effectiveDate,
    lastDate: dates[dates.length-1],
    dayData,
    previousDayData,
    forecasts: {}
  }
  if (data.type == 'routes') {
    const ftrColumns = [
      'index', 'date', ...data.dashboard.features.columns
    ]
    const ftrDates = Object.keys(data.dashboard.features.dates).sort()
    const ftrRows = data.dashboard.features.data.map((row, ix) => {
      return [ix, ftrDates[ix], ...row]
    })
    const ftrDf = new DataFrame(ftrRows, ftrColumns).filter(row => row.get('date') <= effectiveDate)
    const routes = []
    for (const route of data.dashboard.config.routes) {
        const routeData = {
          'name': route.name,
        }
        const weeks = []
        for (const week of route.weeks) {
          weeks.push({
            number: week,
            d3ForecastData: buildRouteD3ForecastData(
              df,
              route.name,
              week,
              effectiveDate
            ),
            d3FeaturesData: buildRouteD3FeaturesData(
              ftrDf,
              route.name,
              week,
              effectiveDate
            ),
            d3PerformanceData: buildRouteD3PerformanceData(
              df,
              route.name,
              week,
              effectiveDate
            ),
        })
        routeData['weeks'] = weeks
      }
      routes.push(routeData)
    }
    record['routes'] = routes
  } else if (data.type == 'viterra') {
    const ftrColumns = [
      'index', 'date', ...data.dashboard.features.columns
    ]
    const ftrDates = Object.keys(data.dashboard.features.dates).sort()
    const ftrRows = data.dashboard.features.data.map((row, ix) => {
      return [ix, ftrDates[ix], ...row]
    })
    const ftrDf = new DataFrame(ftrRows, ftrColumns).filter(row => row.get('date') <= effectiveDate)
    const forecasts = {}
    for (const forecast of data.dashboard.config.forecasts) {
      const forecastData = {
        'name': forecast.name,
        'label': viterraLabels[forecast.name],
        'week': forecast.week,
        'd3ForecastData': buildViterraD3ForecastData(
          df,
          forecast.name,
          forecast.week,
          effectiveDate
        ),
        'd3FeaturesData': buildViterraD3FeaturesData(
          ftrDf,
          forecast.name,
          forecast.week,
          effectiveDate
        ),
        'd3PerformanceData': buildViterraD3PerformanceData(
          df,
          forecast.name,
          forecast.week,
          effectiveDate
        ),
      }
      forecasts[forecast.name] = forecastData
    }
    record['forecasts'] = forecasts
  } else if (data.type == 'commodities') {
    const graphData = df.filter(row => row.get('date') <= effectiveDate).tail(90)
    const commodities = Object.keys(data.configuration.commodities)
    const tenors = buildTenorsData(data.dashboard.tennors)
    record['tenor'] = tenors
    record['forecastGraphs'] = buildForecastGraphs(effectiveDate, df, dayData, previousDayData, tenors, commodities)
    record['openTradesCardsData'] = buildOpenTradesCardsData(dayData, commodities)
    record['realizedCardData'] =  buildRealizedCardData(dayData, firstDate)
    record['contractsData'] = buildContractsData(effectiveDate, tenors, dayData, previousDayData, graphData, commodities)
  } else if (data.type == 'equities') {
    const dateIndex = data.dashboard.dates[effectiveDate]
    record['exitIndications'] = buildExitIndicationsTableData(
      data.dashboard.exit_indications[dateIndex]
    )
    record['recommendations'] = buildRecommendationsTableData(
      data.dashboard.recommendations[dateIndex]
    )
    record['portfolioTableData'] = buildPortfolioTableData(
      dayData, previousDayData
    )
    record['m2mTableData'] = buildM2MTableData(
      dayData, previousDayData
    )
    const filteredDf = df.filter(row => row.get('date') <= effectiveDate)
    record['accountValuePlotData'] = buildAccountValuePlotData(
      filteredDf
    )
    record['closePricesPlotData'] = buildClosePricesPlotData(
      filteredDf
    )
  }
  return record
}
