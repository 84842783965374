import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import VueSocketIO from 'vue-socket.io-extended'
import { io } from 'socket.io-client'
import store from './store'
import { sync } from 'vuex-router-sync'
import { domain, clientId, audience } from "../auth_config.json";
import { Auth0Plugin } from "./auth";
import './assets/tbi.css'
import VueMoment from 'vue-moment'

Vue.use(VueMoment);

// Hold the router state in the store
sync(store, router);

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

const connection = process.env.NODE_ENV === 'development' ? 'http://localhost:8000' : window.location.origin;
const socket = io(connection, {
  reconnection: true,
  reconnectionDelay: 500,
  maxReconnectionAttempts: Infinity,
  path: "/socket.io",
  query: {
    uuid: store.state.uuid
  }
})
const debug = process.env.NODE_ENV === 'development';
if (debug) {
  localStorage.debug = '*'
}

Vue.use(VueSocketIO, socket, {
  store,
  actionPrefix: 'SOCKET_',
  eventToActionTransformer: (actionName) => actionName
});

Vue.config.productionTip = false

const vue = new Vue({
  router,
  render: h => h(App),
  store,
  watch: {
    '$auth.isAuthenticated': async function(value) {
      if (value) {
        this.$store.dispatch('login');
      }
    }
  }
}).$mount('#app')
if (process.env.NODE_ENV == 'development') {
  window._vue = vue;
}
