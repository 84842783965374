//import moment from 'moment'

export const viterraLabels = {
  'pmx_M1_roll': 'Pmx M1',
  'pmx_M2_roll': 'Pmx M2',
  'pmx_M3_roll': 'Pmx M3',
  'capes_M1_roll': 'Capes M1',
  'capes_M2_roll': 'Capes M2',
  'capes_M3_roll': 'Capes M3',
  'pmx_Q2': 'Pmx Q2',
  'pmx_Q3': 'Pmx Q3',
  'pmx_Q4': 'Pmx Q4',
  'capes_Q2': 'Capes Q2',
  'capes_Q3': 'Capes Q3',
  'capes_Q4': 'Capes Q4',
  'P1A_82_proxy_W4': 'P1A',
  'P1A_82_proxy_W5': 'P1A',
  'P1A_82_proxy_W6': 'P1A',
  'P2A_82_proxy_W4': 'P2A',
  'P2A_82_proxy_W5': 'P2A',
  'P2A_82_proxy_W6': 'P2A',
  'P3A_82_proxy_W4': 'P3A',
  'P3A_82_proxy_W5': 'P3A',
  'P3A_82_proxy_W6': 'P3A',
  'P6_82_W4': 'P6',
  'P6_82_W5': 'P6',
  'P6_82_W6': 'P6'
}

export const buildViterraD3ForecastData = function(df, forecast, week, effectiveDate) {
  let baseKey = forecast
  if (forecast.includes('roll')) {
    baseKey = forecast.replace('_W4', '').replace('_W6', '')
  }
  const filtered = df.filter(
    row => (
      row.get('date') <= effectiveDate &&
      row.get(`${baseKey}_spot_price`)
    )
  ).tail(250)
  const spotPrices = filtered.toArray(baseKey + '_spot_price')
  const spots = []
  for (let i of [1, 2, 3, 4, 5]) {
    const s = filtered.toArray(baseKey + '_spot_s' + i).slice(-1)[0]
    if (s) {
      spots.push(s)
    }
  }
  let averageSpot = null
  if (spots.length > 0) {
    averageSpot = spots.reduce((a, b) => a+b, 0) / spots.length
  }
  const values = spotPrices.slice(-4)
  const forecasts = filtered.toArray(baseKey + '_prediction').slice(-4)
  const ll_50s = filtered.toArray(baseKey + '_ll_50').slice(-4)
  const ul_50s = filtered.toArray(baseKey + '_ul_50').slice(-4)
  const ll_70s = filtered.toArray(baseKey + '_ll_70').slice(-4)
  const ul_70s = filtered.toArray(baseKey + '_ul_70').slice(-4)
  return {
    min_value: Math.min(...spotPrices),
    max_value: Math.max(...spotPrices),
    value: values.slice(-1)[0],
    values: values.slice(0, 3),
    spots,
    averageSpot,
    forecast: forecasts.slice(-1)[0],
    forecasts: forecasts.slice(0, 3),
    ll_50: ll_50s.slice(-1)[0],
    ll_50s: ll_50s.slice(0, 3),
    ul_50: ul_50s.slice(-1)[0],
    ul_50s: ul_50s.slice(0, 3),
    ll_70: ll_70s.slice(-1)[0],
    ll_70s: ll_70s.slice(0, 3),
    ul_70: ul_70s.slice(-1)[0],
    ul_70s: ul_70s.slice(0, 3)
  }
}

export const buildViterraD3FeaturesData = function(df, forecast, week, effectiveDate) {
  let baseKey = forecast
  if (forecast.includes('roll')) {
    baseKey = forecast.replace('_W4', '').replace('_W6', '')
  }
  const columns = df.listColumns()
    .filter(c => c.startsWith(baseKey))
  const categories = columns.map(c => c.slice(baseKey.length+1))
  return df
    .filter(row => row.get('date') <= effectiveDate)
    .select(...columns)
    .renameAll(categories)
    .tail(1)
    .toCollection()[0]
}

export const buildViterraD3PerformanceData = function(df, forecast, week, effectiveDate) {
  let baseKey = forecast
  const record = df
    .filter(row => row.get('date') <= effectiveDate)
    .tail(1)
    .toCollection()[0]
  const relDA6M = record[`${baseKey}_da_roll_pct_125`]
  const relForecast6M = record[`${baseKey}_error_norm_roll_pct_125`]
  const relRange6M = record[`${baseKey}_target_inrange_roll_pct_125_50`]
  const relAccuracy6M = (
    relDA6M +
    relForecast6M +
    relRange6M
  ) / 3.0
  const relDA1Y = record[`${baseKey}_da_roll_pct_250`]
  const relForecast1Y = record[`${baseKey}_error_norm_roll_pct_250`]
  const relRange1Y = record[`${baseKey}_target_inrange_roll_pct_250_50`]
  const relAccuracy1Y = (
    relDA1Y +
    relForecast1Y +
    relRange1Y
  ) / 3.0
  const absDA6M = record[`${baseKey}_da_roll_125`]
  const absForecast6M = record[`${baseKey}_median_abs_pct_error_125`]
  const absRange6M = record[`${baseKey}_target_inrange_roll_125_50`]
  const absAccuracy6M = (
    absDA6M +
    absForecast6M +
    absRange6M
  ) / 3.0
  const absDA1Y = record[`${baseKey}_da_roll_250`]
  const absForecast1Y = record[`${baseKey}_median_abs_pct_error_250`]
  const absRange1Y = record[`${baseKey}_target_inrange_roll_250_50`]
  const absAccuracy1Y = (
    absDA1Y +
    absForecast1Y +
    absRange1Y
  ) / 3.0
  const out = {
    relative: {
      past_six_months: {
        accuracy: relAccuracy6M,
        directionalAccuracy: relDA6M,
        forecastAccuracy: relForecast6M,
        rangeAccuracy: relRange6M
      },
      past_year: {
        accuracy: relAccuracy1Y,
        directionalAccuracy: relDA1Y,
        forecastAccuracy: relForecast1Y,
        rangeAccuracy: relRange1Y
      }
    },
    absolute: {
      past_six_months: {
        accuracy: absAccuracy6M,
        directionalAccuracy: absDA6M,
        forecastAccuracy: absForecast6M,
        rangeAccuracy: absRange6M
      },
      past_year: {
        accuracy: absAccuracy1Y,
        directionalAccuracy: absDA1Y,
        forecastAccuracy: absForecast1Y,
        rangeAccuracy: absRange1Y
      }
    },
  }
  return out
}
