export const buildCommodities = function(data) {
  if (!data) return []
  if (data.type != 'commodities') return []
  if (!data.configuration.commodities) return []
  const displayNames = {
    capesize: 'Capesize',
    panamax: 'Panamax',
    iron_ore: 'Iron Ore'
  }
  const commodities = Object.keys(data.configuration.commodities).reduce(
    (acc, commodity) =>  acc.concat([{
        ...data.configuration.commodities[commodity],
        name: commodity,
        // TODO: this should belong to the config we get from the backend
        contracts: ['M1', 'M2', 'M3'],
        displayName: displayNames[commodity]
    }]),
    []
  )
  return commodities
}
