import DataFrame from 'dataframe-js'

export const buildTenorsData = function(data) {
  const months = {
    jan: 'January',
    feb: 'February',
    mar: 'March',
    apr: 'April',
    may: 'May',
    jun: 'June',
    jul: 'July',
    aug: 'August',
    sep: 'September',
    oct: 'October',
    nov: 'November',
    dec: 'December'
  }
  let df = new DataFrame(
    data,
    ['name', 'start', 'end', 'settlementStart',
     'settlementDate', 'settlementDays']
  )
  df = df.withColumn('month', row => months[row.get('name').slice(0, 3)])
  return df
}

export const getTenorForDateAndMonth = function(tenors, date, month) {
  return tenors.filter(row => row.get('end') >= date).slice(month, month+1).toCollection()[0]
}

