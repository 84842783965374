import DataFrame from 'dataframe-js'


export const buildPositionsData = function(rawData) {
  const { open_summary, realized_summary, open_trades, realized_trades } = rawData
  return {
    openSummary: new DataFrame(open_summary.rows, open_summary.columns),
    realizedSummary: new DataFrame(realized_summary.rows, realized_summary.columns),
    openTrades: new DataFrame(open_trades.rows, open_trades.columns),
    realizedTrades: new DataFrame(realized_trades.rows, realized_trades.columns)
  }
}
