import {currencyFormatter} from '@/mixins';

export const buildRecommendationsTableData = function(recommendations) {
  const items = recommendations.reduce((acc, rec) => {
    const action = rec.signal_action.replace('Action.', '')
    const buy = action == "BUY"
    const availableCapital = buy ? rec.buy_capital : rec.sell_capital
    const shares = Math.round(availableCapital / rec.signal_price)
    const netShares = shares * (buy ? 1 : -1)
    const condition = `price ${buy ? 'below' : 'above'} ${currencyFormatter.format(rec.order_limit)}`
    if (shares != 0) {
      acc.push({
        equity: rec.ticker,
        action,
        availableCapital: currencyFormatter.format(availableCapital),
        netShares,
        condition,
        _cellVariants: {
          action: netShares < 0 ? "red" : "green",
        }
      })
    }
    return acc
  }, []).sort((x, y) => x.equity > y.equity)
  const fields = [
    {key: 'equity'},
    {key: 'action'},
    {key: 'availableCapital', thClass: 'text-right', tdClass: 'text-right'},
    {key: 'netShares', thClass: 'text-right', tdClass: 'text-right'},
    {key: 'condition', thClass: 'text-right', tdClass: 'text-right'}
  ]
  return {items, fields}
}

export const buildExitIndicationsTableData = function(exit_indications) {
  const items = exit_indications.reduce((acc, ei) => {
    acc.push({
      equity: ei.ticker,
      action: ei.delta < 0 ? "SELL" : "BUY",
      netShares: Math.round(ei.delta),
      _cellVariants: {
        action: ei.delta < 0 ? "red" : "green",
      }
    })
    return acc
  }, []).sort((x, y) => x.equity > y.equity)
  const fields = [
    {key: 'equity'},
    {key: 'action'},
    {key: 'netShares', thClass: 'text-right', tdClass: 'text-right'}
  ]
  return {items, fields}
}

const extractEquities = function(current, previous) {
  const equities = new Set()
  for (let target of [current, previous]) {
    for (let key of Object.keys(target)) {
      if (key.startsWith('m2m_') && !key.includes('wte')) {
        const equity = key.replace('m2m_', '')
        equities.add(equity)
      }
    }
  }
  return equities
}

const colorClass = x => x < 0 ? "red" : "green"

export const buildM2MTableData = function(current, previous) {
  const equities = extractEquities(current, previous)
  const items = []
  const fields = [{key: 'equity'}]
  const footer_values = {}
  for (let wte of [0, 1, 2, 3, 4]) {
    const key = `m2m_wte${wte}`
    footer_values[key] = 0
  }
  for (let equity of Array.from(equities).sort()) {
    const item = {
      equity,
      '_cellVariants': {}
    }
    for (let wte of [0, 1, 2, 3, 4]) {
      const key = `m2m_wte${wte}`
      const m2m = current[`${key}_${equity}`]
      item[key] = currencyFormatter.format(m2m)
      item._cellVariants[key] = colorClass(m2m)
      footer_values[key] += m2m
    }
    items.push(item)
  }
  for (let wte of [0, 1, 2, 3, 4]) {
    const key = `m2m_wte${wte}`
    const count = wte + 1
    const week = count > 1 ? "weeks" : "week"
    const label = `M2M for exits in ${count} ${week}`
    fields.push({
      key, label, thClass: 'text-right', tdClass: 'text-right',
      footer: footer_values[key],
      footerClass: `text-right table-${colorClass(footer_values[key])}`
    })
  }
  return {
    items,
    fields
  }
}

export const buildPortfolioTableData = function(current, previous) {
  const equities = extractEquities(current, previous)
  const items = []
  const footer_values = {
    net_shares: 0, m2m: 0, m2m_diff: 0, cum_net_profit: 0, pnl_plus_m2m: 0
  }
  for (let equity of Array.from(equities).sort()) {
    const m2m_key = `m2m_${equity}`
    const m2m = current[m2m_key]
    footer_values['m2m'] += m2m
    const prev_m2m = previous[m2m_key]
    const m2m_diff = m2m - prev_m2m
    footer_values['m2m_diff'] += m2m_diff
    const m2m_pct_change = prev_m2m == 0 ? 0 : (m2m_diff / Math.abs(prev_m2m)) * 100
    const cum_net_profit = current[`cum_net_profit_${equity}`] || 0
    footer_values['cum_net_profit'] += cum_net_profit
    let pl_ratio = current[`pnl_ratio_${equity}`]
    pl_ratio = pl_ratio ? pl_ratio.toFixed(2) + ':1' : 'N/A'
    const pnl_plus_m2m = m2m + cum_net_profit
    footer_values['pnl_plus_m2m'] += pnl_plus_m2m
    const netShares = Math.round(current[`delta_${equity}`])
    footer_values['net_shares'] += netShares
    items.push({
      equity,
      netShares,
      m2m: currencyFormatter.format(m2m),
      m2m_diff: currencyFormatter.format(m2m_diff),
      m2m_pct_change: `${m2m_pct_change.toFixed(2)}%`,
      cum_net_profit: currencyFormatter.format(cum_net_profit),
      pl_ratio,
      pnl_plus_m2m: currencyFormatter.format(pnl_plus_m2m),
      _cellVariants: {
        m2m: colorClass(m2m),
        m2m_diff: colorClass(m2m_diff),
        m2m_pct_change: colorClass(m2m_pct_change),
        cum_net_profit: colorClass(cum_net_profit),
        pnl_plus_m2m: colorClass(pnl_plus_m2m),
      }
    })
  }
  footer_values['m2m_pct_change'] = (
    footer_values['m2m_diff'] /
    Math.abs(footer_values['m2m'] - footer_values['m2m_diff'])
  )
  const fields = [
    {key: 'equity'},
    {
      key: 'netShares', thClass: 'text-right', tdClass: 'text-right',
      footer: footer_values['net_shares']
    },
    {
      key: 'm2m', label: 'M2M', thClass: 'text-right', tdClass: 'text-right',
      footer: footer_values['m2m'],
      footerClass: `text-right table-${colorClass(footer_values['m2m'])}`
    },
    {
      key: 'm2m_diff', label: 'M2M Daily Change', thClass: 'text-right',
      tdClass: 'text-right', footer: footer_values['m2m_diff'],
      footerClass: `text-right table-${colorClass(footer_values['m2m_diff'])}`
    },
    {
      key: 'm2m_pct_change', label: 'M2M Daily % Change',
      thClass: 'text-right', tdClass: 'text-right',
      footer: footer_values['m2m_pct_change'],
      footerClass: `text-right table-${colorClass(footer_values['m2m_pct_change'])}`
    },
    {
      key: 'cum_net_profit', label: 'Cum. Net Profit',
      thClass: 'text-right', tdClass: 'text-right',
      footer: footer_values['cum_net_profit'],
      footerClass: `text-right table-${colorClass(footer_values['cum_net_profit'])}`
    },
    {
      key: 'pl_ratio', label: 'Profit/loss Ratio',
      thClass: 'text-right', tdClass: 'text-right',
      footer: current['pnl_ratio'] ? current['pnl_ratio'].toFixed(2) + ':1' : 'N/A',
      footerClass: 'text-right'
    },
    {
      key: 'pnl_plus_m2m', label: 'P&L + M2M', thClass: 'text-right',
      tdClass: 'text-right', footer: footer_values['pnl_plus_m2m'],
      footerClass: `text-right table-${colorClass(footer_values['pnl_plus_m2m'])}`
    }
  ]
  return {
    items,
    fields
  }
}

export const buildAccountValuePlotData = function(df) {
  const dates = df.toArray('date')
  return [
    {
      type: 'scatter',
      x: dates,
      y: df.toArray('account_value'),
      name: 'Account Value'
    },
    {
      type: 'scatter',
      x: dates,
      y: df.toArray('net_outcome'),
      name: 'Cum. Net Profit'
    },
    {
      type: 'scatter',
      x: dates,
      y: df.toArray('m2m'),
      name: 'M2M'
    }
  ]
}

export const buildClosePricesPlotData = function(df) {
  const dates = df.toArray('date')
  const plots = []
  for (let col of df.listColumns()) {
    if (col.endsWith('_close')) {
      const ticker = col.replace('_close', '')
      plots.push({
        type: 'scatter',
        x: dates,
        y: df.toArray(col),
        name: ticker
      })
    }
  }
  return plots.sort((a, b) => a.name < b.name)
}
